import React from 'react';

import RootAuth, {
  RootButtonWrapper,
  RootChildren,
  RootLinkWrapper,
} from './AuthForm.styled';
import {
  BaseCard,
  BaseNavlink,
  FullwidthButton,
  H4,
  Text,
  navlinkMode,
} from 'UI';

import blackLogo from 'assets/images/BlackLogo.png';

const AuthForm = ({
  title,
  subtitle,
  supportLink,
  link,
  children,
  buttonLabel,
  onSubmit,
  loader,
  width = 430,
}) => {
  return (
    <BaseCard p="48px 58px" maxWidth={width} width="100%" margin="0 auto">
      <RootAuth>
        <img src={blackLogo} alt="logo-img" />
        <H4>{title}</H4>
        {subtitle && <Text>{subtitle}</Text>}
        <form>
          <RootChildren>{children}</RootChildren>
        </form>

        {supportLink && (
          <RootLinkWrapper>
            <BaseNavlink
              label={supportLink}
              mode={navlinkMode.primary}
              href={link}
              pseudo={false}
              active
            />
          </RootLinkWrapper>
        )}

        {buttonLabel && (
          <RootButtonWrapper>
            <FullwidthButton
              label={buttonLabel}
              onClick={onSubmit}
              loader={loader}
            />
          </RootButtonWrapper>
        )}
      </RootAuth>
    </BaseCard>
  );
};

export default AuthForm;
