import React from 'react';

import CheckboxRoot, {
  CheckboxInput,
  CheckboxLink,
} from './BaseCheckbox.styled';
import { Text } from 'UI';

const BaseCheckbox = ({ checked, label, onChange, linkLabel, linkRoute }) => {
  return (
    <CheckboxRoot>
      <CheckboxInput checked={checked} onChange={onChange} />
      <Text>
        {label}
        {linkLabel && (
          <CheckboxLink to={linkRoute} target="_blank">
            {linkLabel}
          </CheckboxLink>
        )}
      </Text>
    </CheckboxRoot>
  );
};

export default BaseCheckbox;
