import { InputAdornment } from '@mui/material';
import React from 'react';

import RootBaseInput, { RootFormControl } from './BaseSearch.styled';
import { SmallText } from 'UI';

import SearchIcon from 'assets/images/SearchIcon';

const BaseSearch = ({
  placeholder,
  label,
  onChange,
  name,
  value,
  error,
  helperText,
  ...props
}) => {
  return (
    <RootFormControl>
      {label && <SmallText>{label}</SmallText>}
      <RootBaseInput
        placeholder={placeholder}
        value={value || ''}
        onChange={onChange}
        name={name}
        error={error}
        helperText={helperText}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        {...props}
      />
    </RootFormControl>
  );
};

export default BaseSearch;
