import React from 'react';

const NotificationIcon = ({ active, onClick, ...props }) => {
  return (
    <div style={{ cursor: 'pointer' }} onClick={onClick} {...props}>
      {active ? (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M18.3333 15.0001C18.3333 14.5398 17.9602 14.1667 17.5 14.1667H16.6666V9.8334C16.6666 9.37316 16.2935 9.00006 15.8333 9.00006C15.373 9.00006 14.9999 9.37316 14.9999 9.83341L15 14.1667H4.99996V8.33341C4.99996 7.00733 5.52674 5.73556 6.46443 4.79788C7.40211 3.8602 8.67388 3.33341 9.99996 3.33341C10.1271 3.33341 10.2537 3.33825 10.3796 3.34783C10.7394 3.37521 11.0928 3.18521 11.2189 2.84708C11.3973 2.36831 11.0511 1.76508 10.5436 1.70573C10.348 1.68285 10.1563 1.66675 9.99996 1.66675C8.23185 1.66675 6.53616 2.36913 5.28591 3.61937C4.03567 4.86961 3.33329 6.5653 3.33329 8.33341V14.1667H2.49996C2.03972 14.1667 1.66663 14.5398 1.66663 15.0001C1.66663 15.4603 2.03972 15.8334 2.49996 15.8334H17.5C17.9602 15.8334 18.3333 15.4603 18.3333 15.0001ZM11.6666 19.1667C12.1269 19.1667 12.5 18.7937 12.5 18.3334C12.5 17.8732 12.1269 17.5001 11.6666 17.5001H8.33329C7.87306 17.5001 7.49996 17.8732 7.49996 18.3334C7.49996 18.7937 7.87306 19.1667 8.33329 19.1667H11.6666Z"
            fill="#00CFC8"
          />
          <path
            d="M15.5 7C16.163 7 16.7989 6.73661 17.2678 6.26777C17.7366 5.79893 18 5.16304 18 4.5C18 3.83696 17.7366 3.20107 17.2678 2.73223C16.7989 2.26339 16.163 2 15.5 2C14.837 2 14.2011 2.26339 13.7322 2.73223C13.2634 3.20107 13 3.83696 13 4.5C13 5.16304 13.2634 5.79893 13.7322 6.26777C14.2011 6.73661 14.837 7 15.5 7Z"
            fill="#00CFC8"
            stroke="#00CFC8"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ) : (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_2159_1786)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M18.3333 15.0001C18.3333 14.5398 17.9602 14.1667 17.5 14.1667H16.6666V9.8334C16.6666 9.37316 16.2935 9.00006 15.8333 9.00006C15.373 9.00006 14.9999 9.37316 14.9999 9.83341L15 14.1667H4.99996V8.33341C4.99996 7.00733 5.52674 5.73556 6.46443 4.79788C7.40211 3.8602 8.67388 3.33341 9.99996 3.33341C10.1271 3.33341 10.2537 3.33825 10.3796 3.34783C10.7394 3.37521 11.0928 3.18521 11.2189 2.84708C11.3973 2.36831 11.0511 1.76508 10.5436 1.70573C10.348 1.68285 10.1563 1.66675 9.99996 1.66675C8.23185 1.66675 6.53616 2.36913 5.28591 3.61937C4.03567 4.86961 3.33329 6.5653 3.33329 8.33341V14.1667H2.49996C2.03972 14.1667 1.66663 14.5398 1.66663 15.0001C1.66663 15.4603 2.03972 15.8334 2.49996 15.8334H17.5C17.9602 15.8334 18.3333 15.4603 18.3333 15.0001ZM11.6666 19.1667C12.1269 19.1667 12.5 18.7937 12.5 18.3334C12.5 17.8732 12.1269 17.5001 11.6666 17.5001H8.33329C7.87306 17.5001 7.49996 17.8732 7.49996 18.3334C7.49996 18.7937 7.87306 19.1667 8.33329 19.1667H11.6666Z"
              fill="currentColor"
            />
            <path
              d="M15.5 7C16.163 7 16.7989 6.73661 17.2678 6.26777C17.7366 5.79893 18 5.16304 18 4.5C18 3.83696 17.7366 3.20107 17.2678 2.73223C16.7989 2.26339 16.163 2 15.5 2C14.837 2 14.2011 2.26339 13.7322 2.73223C13.2634 3.20107 13 3.83696 13 4.5C13 5.16304 13.2634 5.79893 13.7322 6.26777C14.2011 6.73661 14.837 7 15.5 7Z"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_2159_1786">
              <rect width="20" height="20" fill="currentColor" />
            </clipPath>
          </defs>
        </svg>
      )}
    </div>
  );
};

export default NotificationIcon;
