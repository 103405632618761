import { FormControl, TextField, styled } from '@mui/material';

const RootFormControl = styled(FormControl)(() => ({
  textAlign: 'left',
}));

const RootBaseInput = styled(TextField)(() => ({
  marginTop: '5px',

  '& .MuiInputBase-root': {
    borderRadius: '16px',
    '&:hover': {
      fieldset: {
        borderColor: '#00CFC8',
      },
    },
  },

  '& .MuiInputBase-input': {
    padding: '14px 15px',
    fontFamily: 'Lato',
    fontSize: '14px',
  },

  '& .Mui-focused': {
    fieldset: {
      borderColor: '#00CFC8 !important',
    },
  },

  '& .Mui-error': {
    fieldset: {
      borderColor: '#FF5454',
    },
  },

  '& .MuiFormHelperText-root': {
    color: '#FF5454',
    fontSize: '12px',
    fontFamily: 'Lato',
    marginLeft: 0,
  },

  fieldset: {
    outline: 'none',
    borderColor: '#D6E0EA',
  },
}));

export { RootFormControl };
export default RootBaseInput;
