import React from 'react';

const LeftArrow = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.5235 6.16667H12.8335C13.2937 6.16667 13.6668 6.53977 13.6668 7.00001C13.6668 7.46024 13.2937 7.83334 12.8335 7.83334H3.5235L7.40433 11.7142C7.72972 12.0396 7.72972 12.5671 7.40433 12.8925C7.07894 13.2179 6.55138 13.2179 6.226 12.8925L0.333496 7.00001L6.226 1.10751C6.55138 0.782117 7.07894 0.782118 7.40433 1.10751C7.72972 1.43289 7.72972 1.96045 7.40433 2.28584L3.5235 6.16667Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default LeftArrow;
