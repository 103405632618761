import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Autocomplete, IconButton } from '@mui/material';
import React from 'react';

import RootSelectField, {
  OptionRoot,
  RootFormControl,
} from './BaseSelect.styled';
import { SmallText } from 'UI';

import theme from 'theme/theme';

const BaseSelect = ({
  value,
  onChange,
  options,
  placeholder,
  fullWidth = true,
  label,
  sx,
  ...props
}) => {
  return (
    <RootFormControl fullWidth={fullWidth}>
      {label && <SmallText sx={{ marginBottom: '5px' }}>{label}</SmallText>}
      <Autocomplete
        value={value || null}
        onChange={onChange}
        options={options || []}
        renderOption={(propsOption, option) => (
          <OptionRoot {...propsOption}>{option?.label}</OptionRoot>
        )}
        renderInput={(params) => (
          <RootSelectField {...params} placeholder={placeholder} />
        )}
        componentsProps={{
          popupIndicator: {
            component: (props) => (
              <IconButton disableRipple {...props}>
                <KeyboardArrowDownIcon sx={{ color: theme.headlines }} />
              </IconButton>
            ),
          },
        }}
        sx={sx}
        fullWidth
        {...props}
      />
    </RootFormControl>
  );
};

export default BaseSelect;
