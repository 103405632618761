import { styled } from '@mui/material';
import theme from 'theme/theme';

const RootWysiwyg = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '48px',

  '@media(max-width: 620px)': {
    gap: '15px !important',
  },
}));

const WysiwygContent = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  p: {
    color: theme.primary,
  },
}));

export { WysiwygContent };
export default RootWysiwyg;
