import { createSlice } from '@reduxjs/toolkit';
import REDUX_CHAT, { getChatHistoryAction, sendMessageAction } from './actions';

const initialState = {
  loading: 'not loaded',
  chatHistory: null,
  chatPageData: {},
  messageResponse: null,
};

const chatSlice = createSlice({
  name: REDUX_CHAT,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getChatHistoryAction.pending, (state) => {
      state.loading = 'loading';
    });
    builder.addCase(getChatHistoryAction.fulfilled, (state, action) => {
      state.loading = 'loaded';
      state.chatHistory = action.payload.data;
      state.chatPageData = action.payload.pageData;
    });
    builder.addCase(getChatHistoryAction.rejected, (state) => {
      state.loading = 'error';
      state.chatHistory = null;
      state.chatPageData = {};
    });

    builder.addCase(sendMessageAction.pending, (state) => {
      state.loading = 'loading';
    });
    builder.addCase(sendMessageAction.fulfilled, (state, action) => {
      state.loading = 'loaded';
      state.messageResponse = action.payload;
    });
    builder.addCase(sendMessageAction.rejected, (state) => {
      state.loading = 'error';
      state.messageResponse = null;
    });
  },
});

export default chatSlice.reducer;
