import { styled } from '@mui/material';

import bg from 'assets/images/LockedBg.png';

const LockedRoot = styled('div')(() => ({
  height: '100vh',
  backgroundImage: `url(${bg})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  img: {
    maxWidth: '30%',
  },
}));

export default LockedRoot;
