import React from 'react';

import NotificationPopover from '../NotificationPopover/NotificationPopover';
import { SmallText } from 'UI';
import { Logo } from 'layout';
import RootNavigation, {
  NavigationActions,
  NavigationAvatar,
  NavigationAvatarInner,
  NavigationInner,
  NavigationMenu,
} from './Navigation.styled';

import { HOMEPAGE_ROUTE } from 'routes/path';

import defaultAvatar from 'assets/images/avatar.png';

import { useUser } from 'store/slices/user';

const Navigation = () => {
  const { userInfo } = useUser();

  return (
    <RootNavigation>
      <NavigationInner>
        <Logo href={HOMEPAGE_ROUTE} />

        <NavigationMenu>
          <NavigationActions>
            <NotificationPopover />

            <NavigationAvatarInner>
              <SmallText>
                {userInfo?.firstName} {userInfo?.lastName}
              </SmallText>
              <NavigationAvatar
                src={userInfo?.photoPath || defaultAvatar}
                alt="avatar"
              />
            </NavigationAvatarInner>
          </NavigationActions>
        </NavigationMenu>
      </NavigationInner>
    </RootNavigation>
  );
};

export default Navigation;
