import { styled } from '@mui/material';

const DashboardContent = styled('div')(({ sidebar }) => ({
  padding: sidebar ? '148px 0px 48px 388px' : '148px 0px 48px 168px',

  h2: {
    marginBottom: 36,
  },
}));

export default DashboardContent;
