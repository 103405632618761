import { Modal } from '@mui/material';
import React from 'react';

import BaseModalRoot, {
  ActionsRoot,
  CancelButton,
  CloseIconRoot,
  CloseWrapper,
  ContentRoot,
  SubmitButton,
} from './BaseModal.styled';
import { H4 } from 'UI';

const BaseModal = ({
  title,
  open,
  children,
  onSubmit,
  onCancel,
  onClose,
  submit = true,
  cancel = true,
  submitLabel = 'Okay',
  cancelLabel = 'Cancel',
  disableSubmit = false,
}) => {
  return (
    <Modal open={open} onClose={onClose}>
      <BaseModalRoot>
        <CloseWrapper onClick={onClose}>
          <CloseIconRoot />
        </CloseWrapper>

        <H4 sx={{ textAlign: 'center', lineHeight: 1.8 }}>{title}</H4>

        <ContentRoot>{children}</ContentRoot>

        <ActionsRoot>
          {cancel && <CancelButton onClick={onCancel} label={cancelLabel} />}
          {submit && (
            <SubmitButton
              onClick={onSubmit}
              label={submitLabel}
              disabled={disableSubmit}
            />
          )}
        </ActionsRoot>
      </BaseModalRoot>
    </Modal>
  );
};

export default BaseModal;
