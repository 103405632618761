import { AppBar, Avatar, styled } from '@mui/material';

import theme from 'theme/theme';

const RootNavigation = styled(AppBar)(() => ({
  backgroundColor: theme.headlines,
  boxShadow: 'none',
  padding: '24px 0',
  color: theme.white,
  '@media(max-width: 1024px)': {
    img: {
      maxWidth: '150px',
    },
  },
}));

const NavigationInner = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '20px',
  padding: '0 48px',
}));

const NavigationMenu = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  marginLeft: 'auto',
  gap: '45px',
  a: {
    flex: '0 0 auto',
  },
}));

const ButtonGroup = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '24px',
}));

const NavigationActions = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '40px',
}));

const NavigationAvatarInner = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '20px',

  p: {
    color: theme.white,
  },
}));

const NavigationAvatar = styled(Avatar)(() => ({
  width: '48px',
  height: '48px',
}));

export {
  NavigationInner,
  NavigationMenu,
  ButtonGroup,
  NavigationAvatar,
  NavigationActions,
  NavigationAvatarInner,
};
export default RootNavigation;
