import React from 'react';

const SearchIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2208_2731)">
        <path
          d="M15.0257 13.8475L18.0052 16.8263C18.3308 17.1518 18.3309 17.6798 18.0053 18.0054C17.6796 18.331 17.1517 18.331 16.8262 18.0053L13.8473 15.0258C12.5196 16.0901 10.8682 16.669 9.1665 16.6666C5.0265 16.6666 1.6665 13.3066 1.6665 9.16663C1.6665 5.02663 5.0265 1.66663 9.1665 1.66663C13.3065 1.66663 16.6665 5.02663 16.6665 9.16663C16.6689 10.8683 16.09 12.5197 15.0257 13.8475ZM13.354 13.2291C14.4116 12.1415 15.0022 10.6837 14.9998 9.16663C14.9998 5.94329 12.389 3.33329 9.1665 3.33329C5.94317 3.33329 3.33317 5.94329 3.33317 9.16663C3.33317 12.3891 5.94317 15 9.1665 15C10.6835 15.0023 12.1414 14.4117 13.229 13.3541L13.354 13.2291Z"
          fill="#363636"
        />
      </g>
      <defs>
        <clipPath id="clip0_2208_2731">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SearchIcon;
