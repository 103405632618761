import { useSelector, useDispatch } from 'react-redux';
import { useCallback } from 'react';

import {
  loginAction,
  recoveryAction,
  resetPasswordAction,
  signAction,
} from './actions';

const useAuthorization = () => {
  const dispatch = useDispatch();
  const { loading, authorized, token } = useSelector(
    (state) => state.authorization,
  );
  const isAuthLoading = loading === 'loading';

  const login = useCallback(
    async (payload) => {
      const response = await dispatch(loginAction(payload));
      return response.payload;
    },
    [dispatch],
  );

  const sign = useCallback(
    async (payload) => {
      const response = await dispatch(signAction(payload));
      return response.payload;
    },
    [dispatch],
  );

  const recoveryPassword = useCallback(
    async (parameters) => {
      const response = await dispatch(recoveryAction(parameters));
      return response.payload;
    },
    [dispatch],
  );

  const resetPassword = useCallback(
    async (payload) => {
      const response = await dispatch(resetPasswordAction(payload));
      return response.payload;
    },
    [dispatch],
  );

  return {
    isAuthLoading,
    authorized,
    token,
    login,
    sign,
    recoveryPassword,
    resetPassword,
  };
};

export default useAuthorization;
