import { useSelector, useDispatch } from 'react-redux';
import { useCallback } from 'react';

import {
  getBadgeDirectoryAction,
  getBadgeInfoAction,
  getDownloadAssetsAction,
  publishBadgeAction,
} from './actions';

const useBadge = () => {
  const dispatch = useDispatch();
  const { loading, directory, badgeInfo, directoryPageData } = useSelector(
    (state) => state.badges,
  );
  const isBadgeLoading = loading === 'loading';

  const getDownloadAssets = useCallback(async () => {
    const response = await dispatch(getDownloadAssetsAction());
    return response.payload;
  }, [dispatch]);

  const getBadgeDirectory = useCallback(
    async (payload) => {
      const response = await dispatch(getBadgeDirectoryAction(payload));
      return response.payload;
    },
    [dispatch],
  );

  const getBadgeInfo = useCallback(
    async (payload) => {
      const response = await dispatch(getBadgeInfoAction(payload));
      return response.payload;
    },
    [dispatch],
  );

  const publishBadge = useCallback(
    async (payload) => {
      const response = await dispatch(publishBadgeAction(payload));
      return response.payload;
    },
    [dispatch],
  );

  return {
    badgeInfo,
    isBadgeLoading,
    getBadgeDirectory,
    getBadgeInfo,
    directory,
    publishBadge,
    directoryPageData,
    getDownloadAssets,
  };
};

export default useBadge;
