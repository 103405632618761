import React from 'react';

const DashboardIcon = ({ active }) => {
  return (
    <span style={{ width: 20, height: 20 }}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.33333 13.3333H16.6667V4.16667H3.33333V13.3333ZM10.8333 15V16.6667H13.3333C13.7936 16.6667 14.1667 17.0398 14.1667 17.5C14.1667 17.9602 13.7936 18.3333 13.3333 18.3333H6.66666C6.20642 18.3333 5.83333 17.9602 5.83333 17.5C5.83333 17.0398 6.20642 16.6667 6.66666 16.6667H9.16666V15H2.49333C2.3839 14.9993 2.27567 14.9771 2.17486 14.9345C2.07404 14.892 1.98262 14.83 1.90582 14.752C1.82902 14.674 1.76837 14.5817 1.72733 14.4802C1.68629 14.3788 1.66567 14.2703 1.66666 14.1608V3.33917C1.66666 2.87583 2.04583 2.5 2.49333 2.5H17.5067C17.9633 2.5 18.3333 2.87417 18.3333 3.33917V14.1608C18.3333 14.6242 17.9542 15 17.5067 15H10.8333Z"
          fill="currentColor"
        />
      </svg>
    </span>
  );
};

export default DashboardIcon;
