/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';

import BaseLineRoot from './BaseLine.styled';

const BaseLine = ({ id, parentId, animate = false, visible = true }) => {
  useEffect(() => {
    if (id) {
      const showBlockScrolling = () => {
        let scrollAmt =
          window.pageYOffset || document.documentElement.scrollTop;
        if (scrollAmt + 500 >= parentElement.offsetHeight)
          element.style.display = 'block';
      };

      const element = document.getElementById(id);
      const parentElement = document.getElementById(parentId);
      //Update DOM on scroll
      document.addEventListener('scroll', showBlockScrolling);
    }
  }, []);

  return (
    <BaseLineRoot id={id} animate={animate ? 1 : 0} visible={visible ? 1 : 0} />
  );
};

export default BaseLine;
