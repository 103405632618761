import { createSlice } from '@reduxjs/toolkit';

import REDUX_AUTHORIZATION, {
  loginAction,
  recoveryAction,
  resetPasswordAction,
  signAction,
} from './actions';

const initialState = {
  loading: 'not loaded',
  authorized: false,
  token: {},
};

const authorizationSlice = createSlice({
  name: REDUX_AUTHORIZATION,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(loginAction.pending, (state) => {
      state.loading = 'loading';
    });
    builder.addCase(loginAction.fulfilled, (state, action) => {
      state.loading = 'loaded';
      state.authorized = true;
      state.token = action.payload.accessToken;
    });
    builder.addCase(loginAction.rejected, (state) => {
      state.loading = 'error';
      state.authorized = false;
    });

    builder.addCase(signAction.pending, (state) => {
      state.loading = 'loading';
    });
    builder.addCase(signAction.fulfilled, (state, action) => {
      state.loading = 'loaded';
      state.authorized = true;
      // state.token = action.payload.accessToken;
    });
    builder.addCase(signAction.rejected, (state) => {
      state.loading = 'error';
      state.authorized = false;
    });

    builder.addCase(recoveryAction.pending, (state) => {
      state.loading = 'loading';
    });
    builder.addCase(recoveryAction.fulfilled, (state, action) => {
      state.loading = 'loaded';
    });
    builder.addCase(recoveryAction.rejected, (state) => {
      state.loading = 'error';
    });

    builder.addCase(resetPasswordAction.pending, (state) => {
      state.loading = 'loading';
    });
    builder.addCase(resetPasswordAction.fulfilled, (state, action) => {
      state.loading = 'loaded';
    });
    builder.addCase(resetPasswordAction.rejected, (state) => {
      state.loading = 'error';
    });
  },
});

export default authorizationSlice.reducer;
