import { jwtDecode } from 'jwt-decode';

import DICTIONARY from 'constants/Dictionary';

const usePermissions = () => {
  const token = localStorage.getItem(DICTIONARY.ACCESS_TOKEN) || '';
  let decodeToken = { permissions: null, role: null, jti: null };

  if (token) decodeToken = jwtDecode(token);

  return {
    token,
    decodeToken,
    permissions: decodeToken?.permissions,
    role: decodeToken?.role,
    uid: decodeToken?.jti,
  };
};

export default usePermissions;
