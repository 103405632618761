import { useLocation, useNavigate } from 'react-router';
import React from 'react';

import SidebarRoot, { SidebarContent, SidebarItem } from './Sidebar.styled';

import DICTIONARY from 'constants/Dictionary';
import { HOMEPAGE_ROUTE } from 'routes/path';
import dashboardMenuList from '../menuList';

import { collapseButtonState } from 'UI/BaseCollapseButton/BaseCollapseButton';
import LogoutIcon from 'assets/images/LogoutIcon';
import { BaseCollapseButton } from 'UI';

const Sidebar = ({ visible, onVisible }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem(DICTIONARY.ACCESS_TOKEN);
    navigate(HOMEPAGE_ROUTE);
  };

  const handleNavigate = (url) => () => {
    navigate(url);
  };

  return (
    <SidebarRoot visible={visible ? 1 : 0}>
      <BaseCollapseButton
        active={visible}
        onClick={onVisible}
        state={collapseButtonState.left}
      />
      <SidebarContent>
        {dashboardMenuList.map((item) => (
          <SidebarItem
            active={item?.path === pathname}
            onClick={handleNavigate(item.path)}
            key={item.label}
          >
            {item.icon}
            {visible && <span>{item.label}</span>}
          </SidebarItem>
        ))}
      </SidebarContent>

      <SidebarItem onClick={handleLogout}>
        <LogoutIcon />
        {visible && <span>Log out</span>}
      </SidebarItem>
    </SidebarRoot>
  );
};

export default Sidebar;
