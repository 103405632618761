import { useSelector, useDispatch } from 'react-redux';
import { useCallback, useMemo } from 'react';

import {
  getBadgeTypesAction,
  getChatSessionIdAction,
  getJurisdictionAction,
  getSubscriptionAction,
} from './actions';
import {
  convertBadgeTypes,
  convertJurisdictios,
  convertSubscriptions,
} from 'utils/convert';

const useInfo = () => {
  const dispatch = useDispatch();
  const { loading, subscriptions, jurisdictions, badgeTypes, chatSession } =
    useSelector((state) => state.info);
  const isInfoLoading = loading === 'loading';

  const subscriptionOptions = useMemo(() => {
    return convertSubscriptions(subscriptions);
  }, [subscriptions]);

  const jurisdictionOptions = useMemo(() => {
    return convertJurisdictios(jurisdictions);
  }, [jurisdictions]);

  const badgeTypesOptions = useMemo(() => {
    return convertBadgeTypes(badgeTypes);
  }, [badgeTypes]);

  const getSubscriptions = useCallback(async () => {
    const response = await dispatch(getSubscriptionAction());
    return response.payload;
  }, [dispatch]);

  const getJurisdictions = useCallback(async () => {
    const response = await dispatch(getJurisdictionAction());
    return response.payload;
  }, [dispatch]);

  const getBadgeTypes = useCallback(async () => {
    const response = await dispatch(getBadgeTypesAction());
    return response.payload;
  }, [dispatch]);

  const getChatSessionId = useCallback(async () => {
    const response = await dispatch(getChatSessionIdAction());
    return response.payload;
  }, [dispatch]);

  return {
    chatSession,
    isInfoLoading,
    subscriptions,
    jurisdictions,
    badgeTypes,
    getSubscriptions,
    getJurisdictions,
    jurisdictionOptions,
    subscriptionOptions,
    getBadgeTypes,
    badgeTypesOptions,
    getChatSessionId,
  };
};

export default useInfo;
