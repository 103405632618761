import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api/api';

const REDUX_PAYMENT = 'payment';

const API_PAYMENT_STRIPE = '/payment/stripe/create-session';
const REDUX_PAYMENT_STRIPE = 'payment/stripe';

export const createStripePaymentAction = createAsyncThunk(
  REDUX_PAYMENT_STRIPE,
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.post(API_PAYMENT_STRIPE, payload);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  },
);

export default REDUX_PAYMENT;
