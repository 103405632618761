import React from 'react';

const ListCheckboxIcon = ({ style }) => {
  return (
    <span style={{ display: 'inline-flex', ...style }}>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.00033 17.3337C4.39783 17.3337 0.666992 13.6028 0.666992 9.00033C0.666992 4.39783 4.39783 0.666992 9.00033 0.666992C13.6028 0.666992 17.3337 4.39783 17.3337 9.00033C17.3337 13.6028 13.6028 17.3337 9.00033 17.3337ZM9.00033 15.667C10.7684 15.667 12.4641 14.9646 13.7144 13.7144C14.9646 12.4641 15.667 10.7684 15.667 9.00033C15.667 7.23222 14.9646 5.53652 13.7144 4.28628C12.4641 3.03604 10.7684 2.33366 9.00033 2.33366C7.23222 2.33366 5.53652 3.03604 4.28628 4.28628C3.03604 5.53652 2.33366 7.23222 2.33366 9.00033C2.33366 10.7684 3.03604 12.4641 4.28628 13.7144C5.53652 14.9646 7.23222 15.667 9.00033 15.667ZM8.16949 12.3337L5.22282 9.38699C4.89744 9.0616 4.89744 8.53405 5.22283 8.20866C5.54821 7.88327 6.07577 7.88327 6.40116 8.20866L8.16949 9.97699L11.7445 6.40125C12.0699 6.07579 12.5975 6.07567 12.9231 6.40098C13.2487 6.72643 13.2488 7.25431 12.9233 7.57988L8.16949 12.3337Z"
          fill="currentColor"
        />
      </svg>
    </span>
  );
};

export default ListCheckboxIcon;
