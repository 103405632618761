import {
  Pagination,
  TableCell,
  TableHead,
  TableRow,
  styled,
} from '@mui/material';

import theme from 'theme/theme';

const TableHeadRoot = styled(TableHead)(() => ({
  'th:first-child': {
    borderTopLeftRadius: 20,
  },
  'th:last-child': {
    borderTopRightRadius: 20,
  },
}));

const TableHeadCell = styled(TableCell)(() => ({
  backgroundColor: '#505762',
  fontFamily: 'Lato',
  fontWeight: '400',
  fontSize: 16,
  padding: '24px 32px',
  lineHeight: '1.4',
  textAlign: 'left',
  color: theme.white,
}));

const TableBodyRow = styled(TableRow)(() => ({
  transition: 'all .3s',
  '&:hover': {
    backgroundColor: '#E5EAEF',
  },
}));

const TableBodyCell = styled(TableCell)(() => ({
  fontFamily: 'Lato',
  fontWeight: '400',
  fontSize: 16,
  padding: '24px 32px',
  lineHeight: '1.4',
  textAlign: 'left',
  color: theme.primary,
  borderBottom: `1px solid #D6E0EA`,
  transition: 'all .3s',
}));

const RootPagination = styled('div')(() => ({
  borderTop: `1px solid #D6E0EA`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '48px 10px',
}));

const TablePagination = styled(Pagination)(() => ({
  button: {
    minWidth: 26,
    height: 26,
    fontFamily: 'Lato',
    fontSize: '14px',
    borderRadius: '50%',
    '&.Mui-selected': {
      backgroundColor: theme.secondary,
      color: '#fff',
      '&:hover': {
        backgroundColor: theme.secondary,
        color: '#fff',
      },
    },
    '&:hover': {
      backgroundColor: theme.secondary,
      color: '#fff',
    },
  },
}));

const EmptyTableRoot = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 30,
  gap: 10,
  svg: {
    width: 60,
    height: 60,
    color: theme.border,
  },
  h4: {
    color: theme.border,
  },
}));

export {
  RootPagination,
  TableHeadCell,
  TableHeadRoot,
  TableBodyCell,
  TableBodyRow,
  TablePagination,
  EmptyTableRoot,
};
