import { NavLink } from 'react-router-dom';
import { styled } from '@mui/material';
import theme from 'theme/theme';

const PrimaryBaseNavlink = styled(NavLink)(({ active, pseudo }) => ({
  fontFamily: 'Lato',
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: 1.3,
  color: active ? theme.brand : theme.white,
  position: 'relative',
  textDecoration: 'none',
  transition: 'all 0.3s',

  '&::before': {
    content: '""',
    position: 'absolute',
    filter: 'blur(2rem)',
    width: 33,
    height: 33,
    backgroundColor: active ? theme.brand : 'transparent',
    borderRadius: '50%',
    transition: 'all 0.5s',
    left: '50%',
    transform: 'translate(-50%)',
    top: '-10px',
    display: pseudo ? 'block' : 'none',
  },

  '&:hover': {
    color: theme.brand,

    '&::before': {
      backgroundColor: theme.brand,
    },
  },
}));

const SecondaryBaseNavlink = styled(NavLink)(({ active }) => ({
  fontFamily: 'Lato',
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: 1.3,
  color: active ? theme.white : theme.secondary,
  textDecoration: 'none',
  transition: 'all 0.3s',
  '&:hover': {
    color: theme.white,
  },
}));

export { SecondaryBaseNavlink };
export default PrimaryBaseNavlink;
