import { FormControl, TextareaAutosize, styled } from '@mui/material';
import theme from 'theme/theme';

const RootFormControl = styled(FormControl)(() => ({
  textAlign: 'left',
}));

const TextareaRoot = styled(TextareaAutosize)(({ error }) => ({
  borderRadius: '16px',
  padding: '14px 15px',
  fontFamily: 'Lato',
  fontSize: '14px',
  border: error ? `1px solid ${theme.error}` : '1px solid #D6E0EA',
  resize: 'none',
  width: '100%',
  outlineColor: '#00CFC8 !important',

  '&:focus': {
    borderColor: '#00CFC8 !important',
  },

  '&:focus-visible': {
    borderColor: '#00CFC8 !important',
  },

  '& .Mui-error': {
    borderColor: '#FF5454',
  },
}));

export { TextareaRoot };
export default RootFormControl;
