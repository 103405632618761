import React from 'react';

import RootFormControl, { TextareaRoot } from './BaseTextarea.styled';
import { SmallText } from 'UI';
import { FormHelperText } from '@mui/material';

const BaseTextarea = ({
  label,
  value,
  onChange,
  error,
  helperText,
  fullWidth,
}) => {
  return (
    <RootFormControl fullWidth={fullWidth}>
      {label && <SmallText sx={{ marginBottom: '5px' }}>{label}</SmallText>}
      <TextareaRoot
        value={value || ''}
        onChange={onChange}
        minRows={5}
        error={error}
      />
      {helperText && (
        <FormHelperText
          error
          sx={{ marginLeft: 0, fontSize: '12px', fontFamily: 'Lato' }}
        >
          {helperText}
        </FormHelperText>
      )}
    </RootFormControl>
  );
};

export default BaseTextarea;
