import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api/api';

const REDUX_AUTHORIZATION = 'authorization';

const API_LOGIN = '/authorization/login';
const REDUX_LOGIN = 'authorization/login';

const API_RECOVERY = '/authorization/password/restore/send';
const REDUX_RECOVERY = 'authorization/restore';

const API_RESET_PASSWORD = '/authorization/password/restore/confirm';
const REDUX_RESET_PASSWORD = 'authorization/reset';

const API_SIGN = '/authorization/registration';
const REDUX_SIGN = 'authorization/sign';

export const loginAction = createAsyncThunk(
  REDUX_LOGIN,
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.post(API_LOGIN, payload);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  },
);

export const recoveryAction = createAsyncThunk(
  REDUX_RECOVERY,
  async (parameters, { rejectWithValue }) => {
    try {
      const response = await api.post(
        API_RECOVERY,
        {},
        {
          params: parameters,
        },
      );
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  },
);

export const resetPasswordAction = createAsyncThunk(
  REDUX_RESET_PASSWORD,
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.post(API_RESET_PASSWORD, payload);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  },
);

export const signAction = createAsyncThunk(
  REDUX_SIGN,
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.post(API_SIGN, payload);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  },
);

export default REDUX_AUTHORIZATION;
