import { useSelector, useDispatch } from 'react-redux';
import { useCallback } from 'react';

import { createStripePaymentAction } from './actions';

const usePayment = () => {
  const dispatch = useDispatch();
  const { loading, sessionLink } = useSelector((state) => state.payment);
  const isPaymentLoading = loading === 'loading';

  const createStripeSession = useCallback(
    async (payload) => {
      const response = await dispatch(createStripePaymentAction(payload));
      return response.payload;
    },
    [dispatch],
  );

  return {
    createStripeSession,
    isPaymentLoading,
    sessionLink,
  };
};

export default usePayment;
