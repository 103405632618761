import { Checkbox, styled } from '@mui/material';
import { Link } from 'react-router-dom';
import theme from 'theme/theme';

const CheckboxRoot = styled('div')(() => ({
  display: 'flex',
  gap: 16,
  alignItems: 'center',
}));

const CheckboxInput = styled(Checkbox)(() => ({
  padding: 0,
  color: theme.brand,
  cursor: 'pointer',
  width: 20,
  height: 20,

  '&.Mui-checked': {
    color: theme.brand,
  },
}));

const CheckboxLink = styled(Link)(() => ({
  color: theme.brand,
  fontSize: '16px',
  lineHeight: '1.4',
}));

export { CheckboxInput, CheckboxLink };
export default CheckboxRoot;
