import React from 'react';

const RightArrow = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.4768 6.16667L6.596 2.28584C6.27061 1.96045 6.27061 1.43289 6.596 1.10751C6.92138 0.782117 7.44894 0.782118 7.77433 1.10751L13.6668 7.00001L7.77433 12.8925C7.44894 13.2179 6.92138 13.2179 6.596 12.8925C6.27061 12.5671 6.27061 12.0396 6.596 11.7142L10.4768 7.83334H1.16683C0.706591 7.83334 0.333496 7.46024 0.333496 7C0.333496 6.53977 0.706592 6.16667 1.16683 6.16667H10.4768Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default RightArrow;
