import { useSelector, useDispatch } from 'react-redux';
import { useCallback, useMemo } from 'react';

import {
  getChatHistoryAction,
  getPromtsCountAction,
  sendMessageAction,
} from './actions';

const useChat = () => {
  const dispatch = useDispatch();
  const { loading, chatHistory, messageResponse, chatPageData } = useSelector(
    (state) => state.chat,
  );
  const isChatLoading = loading === 'loading';

  const chatHistoryReverse = useMemo(() => {
    // if (chatHistory) return [...chatHistory].reverse();
    // else return [];
    if (chatHistory) return [...chatHistory];
    else return [];
  }, [chatHistory]);

  const getChatHistory = useCallback(
    async (parameters) => {
      const response = await dispatch(getChatHistoryAction(parameters));
      return response.payload;
    },
    [dispatch],
  );

  const sendMessage = useCallback(
    async (payload) => {
      const response = await dispatch(sendMessageAction(payload));
      return response.payload;
    },
    [dispatch],
  );

  const getPromtsCount = useCallback(async () => {
    const response = await dispatch(getPromtsCountAction());
    return response.payload;
  }, [dispatch]);

  return {
    chatPageData,
    getChatHistory,
    chatHistoryReverse,
    sendMessage,
    isChatLoading,
    chatHistory,
    messageResponse,
    getPromtsCount,
  };
};

export default useChat;
