import { styled } from '@mui/material';

const RootContainer = styled('div')(() => ({
  width: '100%',
  maxWidth: '1380px',
  margin: '0 auto',
  padding: '0 15px',
}));

export default RootContainer;
