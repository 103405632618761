import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api/api';

const REDUX_USER = 'user';

const API_USER_ME = '/user/me';
const REDUX_USER_ME = 'user/me';

const API_USER_SUBSCRIPTION = '/subscription/me';
const REDUX_USER_SUBSCRIPTION = 'user/subscription';

const API_STATISTIC_ME = '/statistic/user/me';
const REDUX_STATISTIC_ME = 'user/statistic';

const API_BADGE_ME = '/user-badge-directory-info/user-badge-info';
const REDUX_BADGE_ME = 'user/badge';

const API_GET_NOTIFICATIONS = '/user-notification/get-notifications';
const REDUX_GET_NOTIFICATIONS = 'user/notifications';

const API_REMOVE_NOTIFICATION = '/user-notification/delete';
const REDUX_REMOVE_NOTIFICATION = 'user/notificationsDelete';

const API_READ_NOTIFICATON = '/user-notification/read';
const REDUX_READ_NOTIFICATION = 'user/notificationRead';

export const deleteNotificationAction = createAsyncThunk(
  REDUX_REMOVE_NOTIFICATION,
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.post(API_REMOVE_NOTIFICATION, payload);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  },
);

export const readNotificationAction = createAsyncThunk(
  REDUX_READ_NOTIFICATION,
  async (notificationId, { rejectWithValue }) => {
    try {
      const response = await api.put(
        API_READ_NOTIFICATON + '/' + notificationId,
      );
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  },
);

export const getNotifications = createAsyncThunk(
  REDUX_GET_NOTIFICATIONS,
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get(API_GET_NOTIFICATIONS);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  },
);

export const getUserMeAction = createAsyncThunk(
  REDUX_USER_ME,
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get(API_USER_ME);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  },
);

export const getSubscriptionMeAction = createAsyncThunk(
  REDUX_USER_SUBSCRIPTION,
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get(API_USER_SUBSCRIPTION);
      return response.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  },
);

export const getStatisticMeAction = createAsyncThunk(
  REDUX_STATISTIC_ME,
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get(API_STATISTIC_ME);
      return response.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  },
);

export const getBadgeMeAction = createAsyncThunk(
  REDUX_BADGE_ME,
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get(API_BADGE_ME);
      return response.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  },
);

export default REDUX_USER;
