import React from 'react';

import RootWysiwyg, { WysiwygContent } from './BaseWysiwyg.styled';
import { BaseButton, H2, buttonType } from 'UI';

export const wysiwygSize = {
  lg: 'lg',
  md: 'md',
};

const BaseWysiwyg = ({
  title,
  children,
  buttonLabel,
  size = wysiwygSize.lg,
  titleSize = wysiwygSize.lg,
  buttonLength,
  onButtonClick,
}) => {
  return (
    <RootWysiwyg
      sx={{
        gap: size === wysiwygSize.lg ? '48px' : '24px',
      }}
    >
      {title && (
        <H2
          style={{
            fontSize: titleSize === wysiwygSize.lg ? '32px' : '24px',
            fontWeight: titleSize === wysiwygSize.lg ? 600 : 600,
          }}
        >
          {title}
        </H2>
      )}
      <WysiwygContent>{children}</WysiwygContent>
      {buttonLabel && (
        <BaseButton
          label={buttonLabel}
          type={buttonType.primary}
          length={buttonLength || 100}
          sx={{ padding: '14px 24px', borderRadius: 24 }}
          onClick={onButtonClick}
        />
      )}
    </RootWysiwyg>
  );
};

export default BaseWysiwyg;
