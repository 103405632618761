import React from 'react';

import RootBaseList, { BaseListItem } from './BaseList.styled';

import ListCheckboxIcon from 'assets/images/ListCheckboxIcon';
import { Text } from 'UI';
import theme from 'theme/theme';

const BaseList = ({ list, textStyles, iconStyles }) => {
  return (
    <RootBaseList>
      {list.map((item) => (
        <BaseListItem>
          <ListCheckboxIcon style={{ color: theme.brand, ...iconStyles }} />
          <Text sx={{ ...textStyles }}>{item}</Text>
        </BaseListItem>
      ))}
    </RootBaseList>
  );
};

export default BaseList;
