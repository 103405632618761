import React from 'react';

import RootBaseInput, { RootFormControl } from './BaseInput.styled';
import { SmallText } from 'UI';

const BaseInput = ({
  label,
  onChange,
  name,
  value,
  error,
  helperText,
  type,
  fullWidth,
  ...props
}) => {
  return (
    <RootFormControl fullWidth={fullWidth}>
      <SmallText>{label}</SmallText>
      <RootBaseInput
        type={type}
        value={value || ''}
        onChange={onChange}
        name={name}
        error={error}
        helperText={helperText}
        {...props}
      />
    </RootFormControl>
  );
};

export default BaseInput;
