import { createSlice } from '@reduxjs/toolkit';

import REDUX_PAYMENT, { createStripePaymentAction } from './actions';

const initialState = {
  loading: 'not loaded',
  sessionLink: null,
};

const paymentSlice = createSlice({
  name: REDUX_PAYMENT,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(createStripePaymentAction.pending, (state) => {
      state.loading = 'loading';
    });
    builder.addCase(createStripePaymentAction.fulfilled, (state, action) => {
      state.loading = 'loaded';
      state.sessionLink = action.payload.accessToken;
    });
    builder.addCase(createStripePaymentAction.rejected, (state) => {
      state.loading = 'error';
      state.sessionLink = null;
    });
  },
});

export default paymentSlice.reducer;
