import { styled } from '@mui/material';

const RootBaseList = styled('ul')(() => ({
  listStyle: 'none',
  margin: 0,
  padding: 0,
}));

const BaseListItem = styled('li')(() => ({
  margin: 0,
  padding: 0,
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  marginBottom: '24px',
}));

export { BaseListItem };
export default RootBaseList;
