import { Button, keyframes, styled } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import theme from 'theme/theme';

const RootAccentButton = styled(Button)(() => ({
  fontFamily: 'Lato',
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '1.2',
  color: theme.white,
  border: '1px solid' + theme.white,
  borderRadius: 16,
  padding: '8px 24px',
  width: '100%',
  maxWidth: '200px',
  minWidth: 'unset',
  flex: '0 0 auto',
  textTransform: 'unset',
  height: 'unset',
}));

const RootAccentLoaderButton = styled(LoadingButton)(() => ({
  fontFamily: 'Lato',
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '1.2',
  color: theme.white,
  border: '1px solid' + theme.white + ' !important',
  borderRadius: 16,
  padding: '8px 24px !important',
  width: '100%',
  maxWidth: '200px',
  minWidth: 'unset',
  flex: '0 0 auto',
  textTransform: 'unset',
  height: 'unset',
}));

const spin = keyframes`
  0% {
   box-shadow: 0px 10px 30px -10px rgba(0, 207, 200, 0.7);
  }
  50% {
    box-shadow: 0px 10px 30px -10px rgba(0, 207, 200, 0);
  }
  100% {
    box-shadow: 0px 10px 30px -10px rgba(0, 207, 200, 0.7);
  }
`;

const RootPrimaryButton = styled(Button)(() => ({
  fontFamily: 'Lato',
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '1.2',
  color: theme.white,
  border: '1px solid' + theme.brand,
  backgroundColor: theme.brand,
  borderRadius: 16,
  padding: '8px 24px',
  width: '100%',
  maxWidth: '200px',
  minWidth: 'unset',
  flex: '0 0 auto',
  textTransform: 'unset',
  height: 'unset',
  transition: 'all 0.3s',
  animation: `${spin} 4s infinite ease`,

  '&:hover': {
    border: '1px solid' + theme.brand,
    backgroundColor: theme.brand,
    boxShadow: theme.hoverShadow,
    opacity: 0.8,
    '&::before': {
      backgroundColor: theme.brand,
    },
  },
}));

const RootPrimaryLoaderButton = styled(LoadingButton)(() => ({
  fontFamily: 'Lato',
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '1.2',
  color: theme.white,
  border: '1px solid' + theme.brand,
  backgroundColor: theme.brand,
  borderRadius: 16,
  padding: '8px 24px',
  width: '100%',
  maxWidth: '200px',
  minWidth: 'unset',
  flex: '0 0 auto',
  textTransform: 'unset',
  height: 'unset',
  boxShadow: 'none',
  transition: 'all 0.3s',
  '&:hover': {
    border: '1px solid' + theme.brand,
    backgroundColor: theme.brand,
    boxShadow: theme.hoverShadow,
    opacity: 0.8,
  },
  span: {
    color: theme.white,
  },
}));

export { RootAccentButton, RootAccentLoaderButton, RootPrimaryLoaderButton };
export default RootPrimaryButton;
