import React from 'react';

const DirectoryIcon = ({ active }) => {
  return (
    <span style={{ width: 20, height: 20 }}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_2159_2249)">
          <path
            d="M2.57248 7.5H17.4275C17.5428 7.49995 17.6568 7.52382 17.7624 7.57011C17.868 7.6164 17.9629 7.6841 18.0409 7.76892C18.119 7.85375 18.1787 7.95385 18.2161 8.06291C18.2535 8.17197 18.2679 8.2876 18.2583 8.4025L17.5633 16.7358C17.546 16.9441 17.451 17.1382 17.2973 17.2797C17.1436 17.4213 16.9423 17.4999 16.7333 17.5H3.26665C3.05768 17.4999 2.85639 17.4213 2.70266 17.2797C2.54893 17.1382 2.45399 16.9441 2.43665 16.7358L1.74164 8.4025C1.73208 8.2876 1.74645 8.17197 1.78387 8.06291C1.82128 7.95385 1.88092 7.85375 1.95901 7.76892C2.0371 7.6841 2.13194 7.6164 2.23753 7.57011C2.34313 7.52382 2.45718 7.49995 2.57248 7.5ZM4.03331 15.8333H15.9666L16.5216 9.16667H3.47831L4.03331 15.8333ZM11.1783 4.16667H16.6666C16.8877 4.16667 17.0996 4.25446 17.2559 4.41074C17.4122 4.56702 17.5 4.77899 17.5 5V5.83333H2.49998V3.33333C2.49998 3.11232 2.58778 2.90036 2.74406 2.74408C2.90034 2.5878 3.1123 2.5 3.33331 2.5H9.51164L11.1783 4.16667Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_2159_2249">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </span>
  );
};

export default DirectoryIcon;
