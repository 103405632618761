import React from 'react';

import RootContainer from './BaseContainer.styled';

export const containerSize = {
  lg: 'lg',
  mb: 'mb',
  sm: 'sm',
};

const BaseContainer = ({ children, size = containerSize.lg }) => {
  if (size === containerSize.mb)
    return (
      <RootContainer sx={{ maxWidth: '1160px' }}>{children}</RootContainer>
    );

  if (size === containerSize.sm)
    return <RootContainer sx={{ maxWidth: '920px' }}>{children}</RootContainer>;

  return <RootContainer>{children}</RootContainer>;
};

export default BaseContainer;
