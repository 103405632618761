import { styled } from '@mui/material';
import theme from 'theme/theme';

const PriceCardRoot = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
}));

const PriceText = styled('div')(() => ({
  marginTop: '24px',
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'center',
  gap: '5px',
  fontFamily: 'Lato',
  span: {
    fontWeight: 500,
    fontSize: '18px',
    color: theme.primary,
    lineHeight: '1.4',
  },
  p: {
    margin: 0,
    fontWeight: 600,
    fontSize: '32px',
    color: theme.primary,
  },
}));

const PriceList = styled('div')(() => ({
  marginTop: '48px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '24px',
  marginBottom: '48px',
}));

const PriceItem = styled('div')(() => ({
  display: 'flex',
  alignItems: 'flex-start',
  gap: '16px',
  p: {
    textAlign: 'left',
  },
}));

const Line = styled('div')(() => ({
  position: 'absolute',
  top: 0,
  left: '50%',
  transform: 'translateX(-50%)',
  borderRadius: '2px',
  maxWidth: '200px',
  width: '100%',
  height: '4px',
  backgroundColor: theme.brand,
}));

export { PriceText, PriceList, PriceItem, Line };
export default PriceCardRoot;
