import {
  ABOUT_US_ROUTE,
  BADGES_ROUTE,
  BADGE_DIRECTORY_ROUTE,
  OUR_PRODUCT_ROUTE,
} from 'routes/path';

const menuList = [
  {
    path: ABOUT_US_ROUTE,
    label: 'About Us',
  },
  {
    path: OUR_PRODUCT_ROUTE,
    label: 'Our Product',
  },
  {
    path: BADGES_ROUTE,
    label: 'Badges',
  },
  {
    path: BADGE_DIRECTORY_ROUTE,
    label: 'Badge Directory',
  },
];

export default menuList;
