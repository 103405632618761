import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api/api';

const REDUX_BADGE = 'badge';

const API_BADGE_DIRECTORY = '/user-badge-directory-info/find-published-users';
const REDUX_BADGE_DIRECTORY = 'badge/directoryAll';

const API_BADGE_INFO = '/badge-info';
const REDUX_BADGE_INFO = 'badge/info';

const API_PUBLISH_BADGE = '/claim/badge';
const REDUX_PUBLISH_BADGE = 'badge/publish';

const API_ASSETS_DOWNLOAD = '/asserts/download-zip';
const REDUX_ASSETS_DOWNLOAD = 'badge/download';

export const getDownloadAssetsAction = createAsyncThunk(
  REDUX_ASSETS_DOWNLOAD,
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get(API_ASSETS_DOWNLOAD, {
        responseType: 'blob',
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  },
);

export const getBadgeDirectoryAction = createAsyncThunk(
  REDUX_BADGE_DIRECTORY,
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.post(API_BADGE_DIRECTORY, { ...payload });
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  },
);

export const getBadgeInfoAction = createAsyncThunk(
  REDUX_BADGE_INFO,
  async (badgeType, { rejectWithValue }) => {
    try {
      const response = await api.get(API_BADGE_INFO, {
        params: {
          type: badgeType,
        },
      });
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  },
);

export const publishBadgeAction = createAsyncThunk(
  REDUX_PUBLISH_BADGE,
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.post(API_PUBLISH_BADGE, { ...payload });
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  },
);

export default REDUX_BADGE;
