import React from 'react';

import PrimaryBaseNavlink, { SecondaryBaseNavlink } from './BaseNavlink.styled';

export const navlinkMode = {
  primary: 'primary',
  secondary: 'secondary',
};

const BaseNavlink = ({
  href,
  label,
  active,
  mode = navlinkMode.secondary,
  onClick,
  pseudo = true,
}) => {
  if (mode === navlinkMode.primary)
    return (
      <PrimaryBaseNavlink
        pseudo={pseudo ? 1 : 0}
        to={href}
        active={active ? 1 : 0}
        onClick={onClick}
      >
        {label}
      </PrimaryBaseNavlink>
    );

  return (
    <SecondaryBaseNavlink to={href} active={active ? 1 : 0} onClick={onClick}>
      {label}
    </SecondaryBaseNavlink>
  );
};

export default BaseNavlink;
