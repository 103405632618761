import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api/api';

const REDUX_INFO = 'info';

const API_SUBSCRIPTION = '/subscription/all';
const REDUX_SUBSCRIPTION = 'info/subscription';

const API_JURISDICTION = '/jurisdiction/all';
const REDUX_JURISDICTION = 'info/jurisdiction';

const API_BADGE_TYPES = '/badge-info/types';
const REDUX_BADGE_TYPES = 'info/badgeTypes';

const API_CHAT_SESSION = '/chat/session-id';
const REDUX_CHAT_SESSION = '/info/sessionId';

export const getSubscriptionAction = createAsyncThunk(
  REDUX_SUBSCRIPTION,
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get(API_SUBSCRIPTION);
      return response.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  },
);

export const getJurisdictionAction = createAsyncThunk(
  REDUX_JURISDICTION,
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get(API_JURISDICTION);
      return response.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  },
);

export const getBadgeTypesAction = createAsyncThunk(
  REDUX_BADGE_TYPES,
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get(API_BADGE_TYPES);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  },
);

export const getChatSessionIdAction = createAsyncThunk(
  REDUX_CHAT_SESSION,
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.post(API_CHAT_SESSION, { ...payload });
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  },
);

export default REDUX_INFO;
