import { styled } from '@mui/material';

import theme from 'theme/theme';

const RootFooter = styled('div')(() => ({
  backgroundColor: theme.headlines,
  paddingTop: 16,
  color: theme.white,

  '@media(max-width: 1024px)': {
    img: {
      maxWidth: '150px',
    },
  },

  '@media(max-width: 620px)': {
    img: {
      maxWidth: '100px',
    },
  },
}));

const RootCopyright = styled('div')(() => ({
  padding: '16px 0',
  textAlign: 'center',
  fontSize: '13px',
  lineHeight: '1.5',
  fontWeight: 300,
  color: theme.secondary,

  '@media(max-width: 1024px)': {
    padding: '15px 0',
  },
}));

const RootFooterMenu = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '20px',

  '@media(max-width: 1024px)': {
    gap: '15px',
    flexDirection: 'column',
  },
}));

const FooterMenuList = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '45px',

  '@media(max-width: 1024px)': {
    gap: '20px',
  },
}));

export { RootCopyright, FooterMenuList, RootFooterMenu };
export default RootFooter;
