/* eslint-disable react-hooks/exhaustive-deps */
import { StompSessionProvider, useSubscription } from 'react-stomp-hooks';
import React, { Fragment, useContext, useEffect, useState } from 'react';

import { TitleContext } from 'providers/TitleContext';

import ClaimBadgeModal from 'features/DashboardPage/components/ClaimBadgeModal/ClaimBadgeModal';
import DashboardContent from './DashboardContent/DashboardContent';
import Navigation from './Navigation/Navigation';
import Sidebar from './Sidebar/Sidebar';
import { H2, Text } from 'UI';

import usePermissions from 'hooks/usePermissions';
import { useUser } from 'store/slices/user';

import DICTIONARY from 'constants/Dictionary';
import BaseModal from 'UI/BaseModal/BaseModal';

const WS_SESSION_URL = 'wss://complaix-dev.dataox.io/api/ws-session';
const WS_URL = 'https://complaix-dev.dataox.io/api/ws/';

const SubscripeComponent = () => {
  const { uid } = usePermissions();
  const { getBadgeMe, readNotification } = useUser();

  const [lastMessage, setLastMessage] = useState('No message received yet');
  const [messageId, setMessageId] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openClaim, setOpenClaim] = useState(false);

  useSubscription(`/user/${uid}/queue/messages`, (message) => {
    // console.log(message);
    setOpenModal(false);
    if (message.body) {
      const bodyParsed = JSON.parse(message.body);
      const messageFromBody = bodyParsed?.message;
      const messageUUID = bodyParsed?.messageUUID;
      setOpenModal(true);
      setMessageId(messageUUID);
      setLastMessage(messageFromBody);
    }
  });

  const handleRead = () => {
    readNotification(messageId);
    getBadgeMe();
  };

  const handleOkay = () => {
    setOpenClaim(true);
    setOpenModal(false);
  };

  const handleClaimModalState = () => {
    setOpenClaim((prevState) => !prevState);
    setOpenModal(false);
  };

  const handleCloseModal = () => {
    getBadgeMe();
    setOpenModal(false);
  };

  return (
    <Fragment>
      <BaseModal
        open={openModal}
        onClose={handleCloseModal}
        onSubmit={handleOkay}
        title="Congratulations!"
        cancel={false}
      >
        <Text sx={{ fontSize: '16px', textAlign: 'center' }}>
          {lastMessage}
        </Text>
      </BaseModal>
      <ClaimBadgeModal
        open={openClaim}
        onClose={handleClaimModalState}
        customFunc={handleRead}
      />
    </Fragment>
  );
};

const DashboardLayout = ({ children }) => {
  const { uid } = usePermissions();

  const [visibleSidebar, setVisibleSidebar] = useState(false);
  const { title } = useContext(TitleContext);
  const { getUserMe, getUserNotifications } = useUser();

  const [socketOpened, setSocketOpened] = useState(false);

  const handleVisibleSidebar = () => {
    setVisibleSidebar((prevState) => !prevState);
  };

  useEffect(() => {
    const sidebarViewStorage = localStorage.getItem(DICTIONARY.SIDEBAR_VIEW);
    if (sidebarViewStorage) setVisibleSidebar(JSON.parse(sidebarViewStorage));
  }, []);

  useEffect(() => {
    localStorage.setItem(DICTIONARY.SIDEBAR_VIEW, visibleSidebar);
  }, [visibleSidebar]);

  useEffect(() => {
    if (uid) {
      const socket = new WebSocket(WS_SESSION_URL + '?hash=' + uid);
      socket.onopen = function (e) {
        console.log('Session socket opened', e);
      };

      socket.onclose = function () {
        console.log('Session socket closed');
      };

      socket.onerror = function (error) {
        console.log(`Session socket error: ${error.message}`);
      };
    }
  }, []);

  useEffect(() => {
    getUserMe().then(() => getUserNotifications());
  }, []);

  return (
    <StompSessionProvider
      url={WS_URL}
      onConnect={() => {
        console.log({
          STOMP_CONNECT: 'TCP connection successfully established',
        });
        setSocketOpened(true);
      }}
      reconnectDelay={100}
    >
      <Navigation />
      <Sidebar visible={visibleSidebar} onVisible={handleVisibleSidebar} />
      <DashboardContent sidebar={visibleSidebar ? 1 : 0}>
        <H2>{title}</H2>
        {children}
        {socketOpened && <SubscripeComponent />}
      </DashboardContent>
    </StompSessionProvider>
  );
};

export default DashboardLayout;
