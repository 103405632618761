import React from 'react';

const LogoutIcon = ({ active }) => {
  return (
    <span style={{ width: 20, height: 20 }}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_2159_2232)">
          <path
            d="M9.99996 18.3334C5.39746 18.3334 1.66663 14.6026 1.66663 10.0001C1.66663 5.39758 5.39746 1.66675 9.99996 1.66675C11.2938 1.66578 12.5701 1.96656 13.7274 2.5452C14.8847 3.12384 15.8911 3.9644 16.6666 5.00008H14.4083C13.446 4.15155 12.2593 3.59872 10.9906 3.40792C9.72184 3.21712 8.42501 3.39646 7.2557 3.92443C6.08638 4.45239 5.09425 5.30655 4.39835 6.38441C3.70245 7.46226 3.33235 8.71802 3.33246 10.001C3.33256 11.284 3.70287 12.5397 4.39895 13.6174C5.09503 14.6952 6.08731 15.5492 7.25671 16.0769C8.42612 16.6047 9.72297 16.7838 10.9917 16.5928C12.2603 16.4018 13.447 15.8488 14.4091 15.0001H16.6675C15.8918 16.0359 14.8853 16.8765 13.7279 17.4552C12.5704 18.0338 11.294 18.3345 9.99996 18.3334ZM15.8333 13.3334V10.8334H9.16663V9.16675H15.8333V6.66675L20 10.0001L15.8333 13.3334Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_2159_2232">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </span>
  );
};

export default LogoutIcon;
