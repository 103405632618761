import { Box, CircularProgress } from '@mui/material';
import React, { useEffect } from 'react';
import NProgress from 'nprogress';
import theme from 'theme/theme';

const stylesBox = {
  position: 'fixed',
  width: '100vw',
  backgroundColor: 'rgba(34, 37, 42, 1)',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 1000,
};

const stylesElementBox = {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 1000,
};

const LoaderScreen = ({
  fullHeight = true,
  fullScreen = true,
  overrideStyles,
  size = 50,
}) => {
  const heightStyles = fullHeight ? '100vh' : '100%';
  const mainStyles = fullScreen ? stylesBox : stylesElementBox;

  NProgress.configure({
    showSpinner: false,
  });

  useEffect(() => {
    NProgress.start();
    return () => {
      NProgress.done();
    };
  }, []);
  return (
    <Box sx={{ ...mainStyles, height: heightStyles, ...overrideStyles }}>
      <CircularProgress size={size} style={{ color: theme.brand }} />
    </Box>
  );
};

export default LoaderScreen;
