import { Fade } from 'react-reveal';

const BaseFade = ({ children, duration = 1200 }) => {
  return (
    <Fade bottom distance="20%" duration={duration}>
      {children}
    </Fade>
  );
};

export default BaseFade;
