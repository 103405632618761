import { styled } from '@mui/material';

const RootAuth = styled('div')(() => ({
  textAlign: 'center',
  h4: {
    marginTop: '24px',
    marginBottom: '14px',
  },
}));

const RootChildren = styled('div')(() => ({
  marginTop: '48px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
}));

const RootButtonWrapper = styled('div')(() => ({
  marginTop: '36px',
}));

const RootLinkWrapper = styled('div')(() => ({
  marginTop: '12px',
  textAlign: 'right',
  a: {
    fontSize: '16px',
    fontWeight: 400,
    textDecoration: 'underline',
  },
}));

export { RootChildren, RootButtonWrapper, RootLinkWrapper };
export default RootAuth;
