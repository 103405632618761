import { createSlice } from '@reduxjs/toolkit';

import REDUX_BADGE, {
  getBadgeDirectoryAction,
  getBadgeInfoAction,
} from './actions';

const initialState = {
  loading: 'not loaded',
  directory: [],
  directoryPageData: {
    currentPage: null,
    totalPages: null,
    totalCount: null,
  },
  badgeInfo: {},
};

const badgeSlice = createSlice({
  name: REDUX_BADGE,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getBadgeDirectoryAction.pending, (state) => {
      state.loading = 'loading';
    });
    builder.addCase(getBadgeDirectoryAction.fulfilled, (state, action) => {
      state.loading = 'loaded';
      state.directory = action.payload?.usersWithBadges;
      state.directoryPageData.currentPage = action.payload?.currentPage;
      state.directoryPageData.totalCount = action.payload?.totalCount;
      state.directoryPageData.totalPages = action.payload?.totalPages;
    });
    builder.addCase(getBadgeDirectoryAction.rejected, (state) => {
      state.loading = 'error';
      state.directory = [];
    });

    builder.addCase(getBadgeInfoAction.pending, (state) => {
      state.loading = 'loading';
    });
    builder.addCase(getBadgeInfoAction.fulfilled, (state, action) => {
      state.loading = 'loaded';
      state.badgeInfo = action.payload;
    });
    builder.addCase(getBadgeInfoAction.rejected, (state) => {
      state.loading = 'error';
      state.badgeInfo = {};
    });
  },
});

export default badgeSlice.reducer;
