import { AppBar, IconButton, styled } from '@mui/material';

import theme from 'theme/theme';

const RootNavigation = styled(AppBar)(() => ({
  backgroundColor: theme.headlines,
  boxShadow: 'none',
  padding: '12px 0',
  color: theme.white,
  '@media(max-width: 1024px)': {
    img: {
      maxWidth: '150px',
    },
  },
}));

const NavigationInner = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '20px',
}));

const NavigationMenu = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  marginLeft: 'auto',
  gap: '45px',
  a: {
    flex: '0 0 auto',
  },

  '@media(max-width: 1024px)': {
    gap: '24px',
  },

  '@media(max-width: 800px)': {
    display: 'none',
  },
}));

const ButtonGroup = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '24px',

  '@media(max-width: 800px)': {
    marginTop: '20px',
  },
}));

const MobileMenuRoot = styled(IconButton)(() => ({
  marginLeft: 'auto',
  color: theme.white,
  display: 'none',

  '@media(max-width: 800px)': {
    display: 'block',
  },
}));

const RootMobileSidebar = styled('div')(({ active }) => ({
  transition: 'all .3s',
  position: 'fixed',
  width: '100vw',
  height: '100vh',
  backgroundColor: theme.headlines,
  top: 92,
  right: active ? 0 : '-100%',
  bottom: 0,
  display: 'none',

  '@media(max-width: 800px)': {
    display: 'block',
  },
}));

const MobileSidebarInner = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  height: '80%',
  a: {
    width: '100%',
    fontSize: '15px',
    padding: '15px 10px',
    textAlign: 'center',
  },
}));

export {
  NavigationInner,
  NavigationMenu,
  ButtonGroup,
  MobileMenuRoot,
  RootMobileSidebar,
  MobileSidebarInner,
};
export default RootNavigation;
