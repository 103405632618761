import { styled } from '@mui/material';

import theme from 'theme/theme';

const H1 = styled('h1')(() => ({
  margin: 0,
  fontFamily: 'Lato',
  fontWeight: 700,
  fontSize: '48px',
  lineHeight: 1.4,
  color: theme.headlines,

  '@media(max-width: 1024px)': {
    fontSize: '32px',
  },

  '@media(max-width: 620px)': {
    fontSize: '26px',
  },
}));

const H2 = styled('h2')(() => ({
  margin: 0,
  fontFamily: 'Lato',
  fontWeight: 600,
  fontSize: '32px',
  lineHeight: 1.4,
  color: theme.headlines,

  '@media(max-width: 1024px)': {
    fontSize: '26px !important',
  },

  '@media(max-width: 620px)': {
    fontSize: '22px !important',
  },
}));

const H3 = styled('h3')(() => ({
  margin: 0,
  fontFamily: 'Lato',
  fontWeight: 600,
  fontSize: '28px',
  lineHeight: 1.4,
  color: theme.headlines,

  '@media(max-width: 620px)': {
    fontSize: '20px',
  },
}));

const H4 = styled('h4')(() => ({
  margin: 0,
  fontFamily: 'Lato',
  fontWeight: 600,
  fontSize: '24px',
  lineHeight: 1.1,
  color: theme.headlines,

  '@media(max-width: 1024px)': {
    fontSize: '18px',
  },

  '@media(max-width: 620px)': {
    fontSize: '16px',
  },
}));

const Text = styled('p')(() => ({
  margin: 0,
  fontFamily: 'Lato',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: 1.3,
  color: theme.headlines,

  '@media(max-width: 620px)': {
    fontSize: '14px',
  },
}));

const SmallText = styled('p')(() => ({
  margin: 0,
  fontFamily: 'Lato',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: 1.3,
  color: theme.headlines,
}));

export { H1, H2, H3, H4, Text, SmallText };
