const convertJurisdictios = (data) => {
  const newData = [];

  data.forEach((item) => {
    const obj = {
      value: item.name,
      label: item.name,
    };

    newData.push(obj);
  });

  return newData;
};

const convertSubscriptions = (data) => {
  const newData = [];

  data.forEach((item) => {
    const obj = {
      value: item.name,
      label: item.name,
    };

    newData.push(obj);
  });

  return newData;
};

const convertBadgeTypes = (data) => {
  const newData = [];

  data.forEach((item) => {
    const obj = {
      value: item,
      label: item,
    };

    newData.push(obj);
  });

  return newData;
};

const upperCase = (value) => {
  if (value) return value.toUpperCase();
  return value;
};

const progressValue = (field) => {
  if (!field) return 0;

  const { current, from } = field;
  const value = ((current * 100) / from).toFixed(1);
  return +value;
};

const progressTextComponent = (field) => {
  if (!field) return 0;

  const { current, from } = field;
  return `<span>${current} / ${from}</span>`;
};

const progressTextTimeComponent = (field) => {
  if (!field) return 0;

  const { current, from } = field;
  let hours = (current / (1000 * 60 * 60)).toFixed(2);
  let fromHours = (from / (1000 * 60 * 60)).toFixed(2);
  return `<span>${hours} h / ${fromHours} h</span>`;
};

function isEmptyObject(obj) {
  for (const prop in obj) {
    if (Object.hasOwn(obj, prop)) {
      return false;
    }
  }

  return true;
}

function areAllValuesNull(obj) {
  for (let key in obj) {
    if (obj[key] !== null) {
      return false;
    }
  }
  return true;
}

export {
  areAllValuesNull,
  isEmptyObject,
  progressTextTimeComponent,
  progressTextComponent,
  convertJurisdictios,
  convertSubscriptions,
  convertBadgeTypes,
  upperCase,
  progressValue,
};
