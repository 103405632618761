import { LinearProgress, styled } from '@mui/material';
import theme from 'theme/theme';

const ProgressRoot = styled(LinearProgress)(() => ({
  height: 18,
  borderRadius: 8,
  border: `1px solid ${theme.border}`,
  backgroundColor: 'transparent',

  '& .MuiLinearProgress-bar': {
    background:
      'linear-gradient(90deg, rgba(0,207,200,1) 50%, rgba(3,255,246,1) 100%)',
    borderRadius: 8,
  },
}));

export default ProgressRoot;
