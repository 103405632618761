import { Box, styled } from '@mui/material';

import theme from 'theme/theme';

const RootCard = styled(Box)(() => ({
  backgroundColor: theme.white,
  borderRadius: '20px',
}));

export default RootCard;
