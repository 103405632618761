const theme = {
  brand: '#00CFC8',
  accent: '#03FFF6',
  headlines: '#22252A',
  primary: '#393E46',
  secondary: '#505762',
  border: '#D6E0EA',
  bg: '#F4F8FC',
  white: '#FFFFFF',
  error: '#FF5454',
  disabled: 'rgba(80, 87, 98, 0.5)',
  hoverShadow: '0px 12px 30px -10px rgba(0, 0, 0, 0.15)',
};

export default theme;
