import { keyframes, styled } from '@mui/material';
import theme from 'theme/theme';

const draw = keyframes`
  0% {
    width: 1%;
  }
  100% {
    width: 100%;
  }
`;

const BaseLineRoot = styled('div')(({ animate, visible }) => ({
  width: '100%',
  height: '3px',
  backgroundColor: theme.brand,
  display: visible ? 'block' : 'none',
  animation: animate ? `${draw} 1.5s ease` : 'unset',
}));

export default BaseLineRoot;
