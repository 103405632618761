import React from 'react';

const SubscriptionIcon = ({ active }) => {
  return (
    <span style={{ width: 20, height: 20 }}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_2159_2240)">
          <path
            d="M1.86913 5.71166L9.57496 1.09166C9.70447 1.01395 9.85267 0.9729 10.0037 0.9729C10.1547 0.9729 10.3029 1.01395 10.4325 1.09166L18.1308 5.71249C18.1926 5.7495 18.2437 5.80189 18.2792 5.86455C18.3147 5.9272 18.3333 5.99798 18.3333 6.06999V16.6667C18.3333 16.8877 18.2455 17.0996 18.0892 17.2559C17.9329 17.4122 17.721 17.5 17.5 17.5H2.49996C2.27895 17.5 2.06698 17.4122 1.9107 17.2559C1.75442 17.0996 1.66663 16.8877 1.66663 16.6667V6.06916C1.66661 5.99715 1.68526 5.92637 1.72075 5.86371C1.75624 5.80106 1.80736 5.74867 1.86913 5.71166ZM3.33329 6.77749V15.8333H16.6666V6.77666L10.0033 2.77666L3.33329 6.77666V6.77749ZM10.05 11.415L14.4633 7.69583L15.5366 8.97083L10.0616 13.585L4.46996 8.97666L5.52996 7.68999L10.05 11.415Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_2159_2240">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </span>
  );
};

export default SubscriptionIcon;
