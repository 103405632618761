import ConsultIcon from 'assets/images/ConsultIcon';
import DashboardIcon from 'assets/images/DashboardIcon';
import DirectoryIcon from 'assets/images/DirectoryIcon';
import SubscriptionIcon from 'assets/images/SubscriptionIcon';

import {
  DASHBOARD_DIRECTORY_ROUTE,
  DASHBOARD_ROUTE,
  DASHBOARD_SUPPORT_ROUTE,
  SUBSCRIPTION_ROUTE,
} from 'routes/path';

const dashboardMenuList = [
  {
    icon: <DashboardIcon />,
    label: 'Dashboard',
    path: DASHBOARD_ROUTE,
  },
  {
    icon: <SubscriptionIcon />,
    label: 'Subscription',
    path: SUBSCRIPTION_ROUTE,
  },
  {
    icon: <ConsultIcon />,
    label: 'Consult with an expert',
    path: DASHBOARD_SUPPORT_ROUTE,
  },
  {
    icon: <DirectoryIcon />,
    label: 'Badge directory',
    path: DASHBOARD_DIRECTORY_ROUTE,
  },
];

export default dashboardMenuList;
