const DICTIONARY = {
  ACCESS_TOKEN: 'm_access_token',
  PAYMENT_PLAN: 'm_payment_plan',
  PAYMENT_STATE: 'm_payment_state',
  BRONZE_BADGE: 'BRONZE',
  SILVER_BADGE: 'SILVER',
  GOLD_BADGE: 'GOLD',
  PLATINUM_BDAGE: 'PLATINUM',
  SIDEBAR_VIEW: 'm_sidebar_view',
  JOURNEY_VIEW: 'm_journey_view',
};

export default DICTIONARY;
