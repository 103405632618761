import { Controller, useForm } from 'react-hook-form';
import React, { useState } from 'react';
import { Box } from '@mui/material';

import BaseModal from 'UI/BaseModal/BaseModal';
import { BaseCheckbox, BaseInput, BaseSelect } from 'UI';

import DICTIONARY from 'constants/Dictionary';
import { STANDARTS_ROUTE } from 'routes/path';

import { areAllValuesNull } from 'utils/convert';

import { useBadge } from 'store/slices/badge';
import { useUser } from 'store/slices/user';

const Q1List = [
  {
    label: 'Beginner',
    name: 'Beginner',
  },
  {
    label: 'Intermediate',
    name: 'Intermediate',
  },
  {
    label: 'Expert',
    name: 'Expert',
  },
];

const Q2List = [
  {
    label: '(Use the list from ICA)',
    name: '(Use the list from ICA)',
  },
];

const Q3List = [
  {
    label: 'Use categories from common web3 aggregators(CMC)',
    name: 'Use categories from common web3 aggregators(CMC)',
  },
];

const isBronzeBadge = (badge) => {
  return badge === DICTIONARY.BRONZE_BADGE;
};

const ClaimBadgeModal = ({ open, onClose, customFunc }) => {
  const { publishBadge } = useBadge();
  const { userBadge } = useUser();

  const { getValues, control } = useForm();
  const [errors, setErrors] = useState({
    userDirectoryName: null,
    question: null,
  });
  const [q, setQ] = useState({});

  const [publicDirectory, setPublicDirectory] = useState(false);
  const [standards, setStandarts] = useState(false);

  const handleStandartsChange = (event) => {
    const value = event.target.checked;
    setStandarts(value);
  };

  const handlePublishDirectoryChange = (event) => {
    const value = event.target.checked;
    setPublicDirectory(value);
  };

  const handleQuestionChange = (question) => (_event, newInputValue) => {
    setQ({ ...q, [question]: newInputValue });
  };

  const handleSubmit = () => {
    const fields = { ...getValues() };
    const newError = { ...errors };

    if (areAllValuesNull(newError)) {
      const payload = {
        userDirectoryName: fields.userDirectoryName,
        isPublished: publicDirectory,
        acceptedStandards: standards,
      };
      publishBadge(payload).then(() => {
        onClose();
        customFunc && customFunc();
      });
    }
  };

  return (
    <BaseModal
      title="Claim Badge"
      open={open}
      onClose={onClose}
      onCancel={onClose}
      disableSubmit={!standards}
      onSubmit={handleSubmit}
    >
      {isBronzeBadge(userBadge?.badgeType) && (
        <Controller
          name="userDirectoryName"
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseInput
              label="Directory Name"
              name="userDirectoryName"
              value={value}
              onChange={onChange}
              error={Boolean(errors.userDirectoryName)}
              helperText={errors.userDirectoryName}
              fullWidth
            />
          )}
        />
      )}

      <BaseCheckbox
        label="Public badge directory"
        checked={publicDirectory}
        onChange={handlePublishDirectoryChange}
      />

      {isBronzeBadge(userBadge?.badgeType) && (
        <Box mt="24px" display="flex" flexDirection="column" gap="16px">
          <BaseSelect
            label="How would you assess your level of experience in web3?"
            options={Q1List}
            onChange={handleQuestionChange('q1')}
            value={q?.q1}
          />

          <BaseSelect
            label="How would you assess your level of experience in compliance?"
            options={Q1List}
            onChange={handleQuestionChange('q2')}
            value={q?.q2}
          />

          <BaseSelect
            label="What region are you interested in operating in?"
            options={Q2List}
            onChange={handleQuestionChange('q3')}
            value={q?.q3}
          />

          <BaseSelect
            label="What sector of web3 are you interested in operating in?"
            options={Q3List}
            onChange={handleQuestionChange('q4')}
            value={q?.q4}
          />
        </Box>
      )}

      <BaseCheckbox
        label="Standards "
        linkLabel="(read more)"
        linkRoute={STANDARTS_ROUTE}
        checked={standards}
        onChange={handleStandartsChange}
      />
    </BaseModal>
  );
};

export default ClaimBadgeModal;
