import { FormControl, TextField, styled } from '@mui/material';

import theme from 'theme/theme';

const RootFormControl = styled(FormControl)(() => ({
  textAlign: 'left',
}));

const RootSelectField = styled(TextField)(() => ({
  width: '100%',

  '& .MuiInputBase-root': {
    borderRadius: '16px',
    '&:hover': {
      fieldset: {
        borderColor: '#00CFC8',
      },
    },
  },

  input: {
    padding: '5px !important',
    fontFamily: 'Lato',
    fontSize: '15px',

    '&::placeholder': {
      fontSize: '16px',
      fontFamily: 'Lato',
      color: theme.headlines,
      opacity: '0.5',
    },
  },

  '& .Mui-focused': {
    fieldset: {
      borderColor: '#00CFC8 !important',
    },
  },

  '& .Mui-error': {
    fieldset: {
      borderColor: '#FF5454',
    },
  },

  '& .MuiFormHelperText-root': {
    color: '#FF5454',
    fontSize: '12px',
    fontFamily: 'Lato',
    marginLeft: 0,
  },

  fieldset: {
    outline: 'none',
    borderColor: '#D6E0EA',
  },
}));

const OptionRoot = styled('div')(() => ({
  fontFamily: 'Lato',
  fontSize: '16px',
}));

export { RootFormControl, OptionRoot };
export default RootSelectField;
