import { IconButton, styled } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { BaseButton, buttonType } from 'UI';

import theme from 'theme/theme';

const BaseModalRoot = styled('div')(() => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  outline: 'none',
  width: '100%',
  maxWidth: 660,
  backgroundColor: theme.white,
  borderRadius: 20,
  padding: '48px 116px',
}));

const CloseIconRoot = styled(CloseIcon)(() => ({
  width: 20,
  height: 20,
}));

const CloseWrapper = styled(IconButton)(() => ({
  position: 'absolute',
  top: '24px',
  right: '24px',
}));

const ContentRoot = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 24,
  margin: '24px 0',
  width: '100%',
}));

const ActionsRoot = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  justifyContent: 'center',
  gap: 32,
}));

const SubmitButton = ({ onClick, label, disabled }) => (
  <BaseButton
    type={buttonType.primary}
    length={200}
    onClick={onClick}
    label={label}
    sx={{ fontSize: '16px', padding: '15px', borderRadius: '24px' }}
    disabled={disabled}
  />
);

const CancelButton = ({ onClick, label }) => (
  <BaseButton
    type={buttonType.primary}
    length={200}
    onClick={onClick}
    label={label}
    sx={{
      fontSize: '16px',
      padding: '15px',
      borderRadius: '24px',
      backgroundColor: 'transparent',
      color: theme.headlines,
      '&:hover': {
        backgroundColor: 'transparent',
        color: theme.headlines,
      },
    }}
  />
);

export {
  ActionsRoot,
  CloseIconRoot,
  CloseWrapper,
  ContentRoot,
  CancelButton,
  SubmitButton,
};
export default BaseModalRoot;
