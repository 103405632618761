import { Popover, styled } from '@mui/material';
import theme from 'theme/theme';

const RootPopover = styled(Popover)(() => ({
  '& .MuiPaper-root': {
    borderRadius: '16px',
    backgroundColor: theme.headlines,
    boxShadow: theme.hoverShadow,
    minHeight: 150,
    maxWidth: 270,
    width: '100%',
  },
}));

const EmptyNotificationWrapper = styled('div')(() => ({
  color: theme.white,
  padding: '24px 24px 10px',

  p: {
    color: theme.white,
    opacity: 0.5,
    fontSize: 14,
  },
}));

const PopoverTitle = styled('div')(() => ({
  padding: '24px 24px 10px',
  p: {
    color: theme.white,
  },
}));

const PopoverContent = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: 16,
}));

const PopoverMessage = styled('div')(() => ({
  cursor: 'pointer',
  position: 'relative',
  width: '100%',
  padding: '8px 32px 8px 24px',
  backgroundColor: theme.headlines,
  transition: 'all .3s',

  p: {
    color: theme.white,
  },

  '&:hover': {
    backgroundColor: theme.primary,
  },
}));

const MessageIndicator = styled('div')(() => ({
  backgroundColor: theme.brand,
  width: 8,
  height: 8,
  borderRadius: '50%',
  position: 'absolute',
  top: 12,
  right: 24,
}));

export {
  PopoverTitle,
  EmptyNotificationWrapper,
  PopoverContent,
  PopoverMessage,
  MessageIndicator,
};
export default RootPopover;
