import { combineReducers } from 'redux';

import { authorizationSlice } from 'store/slices/authorization';
import { badgeSlice } from 'store/slices/badge';
import { chatSlice } from 'store/slices/chat';
import { infoSlice } from 'store/slices/info';
import { paymentSlice } from 'store/slices/payment';
import { userSlice } from 'store/slices/user';

export const rootReducer = combineReducers({
  authorization: authorizationSlice,
  payment: paymentSlice,
  info: infoSlice,
  user: userSlice,
  badges: badgeSlice,
  chat: chatSlice,
});
