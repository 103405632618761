import React from 'react';

import GroupOptionsRoot, {
  OptionGroup,
  OptionRadio,
  OptionRoot,
} from './GroupOptions.styled';
import { H4, Text } from 'UI';

const GroupOptions = ({
  options,
  value,
  onChange,
  label,
  width,
  contentStyles,
}) => {
  const controlProps = (item) => ({
    checked: value === item,
    onChange: onChange,
    value: item,
  });

  return (
    <GroupOptionsRoot>
      <H4>{label}</H4>

      <OptionGroup>
        {options.map((option) => (
          <OptionRoot
            active={option.value === value}
            sx={{ maxWidth: width, ...contentStyles }}
            key={option.value}
          >
            <OptionRadio {...controlProps(option.value)} />
            <Text>{option.label}</Text>
          </OptionRoot>
        ))}
      </OptionGroup>
    </GroupOptionsRoot>
  );
};

export default GroupOptions;
