import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

import DICTIONARY from 'constants/Dictionary';

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(DICTIONARY.ACCESS_TOKEN);
    if (!token) return config;
    if (config?.headers) {
      config.headers = { Authorization: `Bearer ${token}` };
      return config;
    }
  },
  (error) => Promise.reject(error),
);

api.interceptors.response.use(
  (response) => response,
  (error) => {
    const accessToken = localStorage.getItem(DICTIONARY.ACCESS_TOKEN);

    if (accessToken) {
      const decodeToken = jwtDecode(accessToken);

      if (decodeToken.exp * 1000 < new Date().getTime()) {
        localStorage.removeItem(DICTIONARY.ACCESS_TOKEN);
        window.location = '/login';
      }

      // localStorage.removeItem(DICTIONARY.ACCESS_TOKEN);
      // window.location = '/login';
    }

    if (!accessToken) {
      window.location = '/login';
    }

    return Promise.reject(error);
  },
);

export default api;
