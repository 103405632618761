import React from 'react';

import LockedRoot from './LockedPage.styled';

import logo from 'assets/images/LockedLogo.png';

const LockedPage = () => {
  return (
    <LockedRoot>
      <img src={logo} alt="logo" />
    </LockedRoot>
  );
};

export default LockedPage;
