import { NavLink } from 'react-router-dom';
import React from 'react';

import logo from 'assets/images/Logo.png';

const Logo = ({ href = '/' }) => {
  return (
    <NavLink to={href}>
      <img src={logo} alt="logo" />
    </NavLink>
  );
};

export default Logo;
