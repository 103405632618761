import { Radio, styled } from '@mui/material';
import theme from 'theme/theme';

const GroupOptionsRoot = styled('div')(() => ({
  flex: '1 1 0',
  flexDirection: 'column',
  display: 'flex',
  gap: 48,
}));

const OptionRoot = styled('div')(({ active }) => ({
  backgroundColor: theme.white,
  padding: 16,
  borderRadius: 16,
  border: active ? `1px solid ${theme.brand}` : `1px solid ${theme.border}`,
  display: 'flex',
  alignItems: 'center',
  gap: 16,
  width: '100%',
  maxWidth: 320,
}));

const OptionRadio = styled(Radio)(() => ({
  padding: 0,
  color: theme.brand,
  '&.Mui-checked': {
    color: theme.brand,
  },
}));

const OptionGroup = styled('div')(() => ({
  flexDirection: 'column',
  display: 'flex',
  gap: 24,
}));

export { OptionRoot, OptionRadio, OptionGroup };
export default GroupOptionsRoot;
