import { styled } from '@mui/material';

import theme from 'theme/theme';

const SidebarRoot = styled('div')(({ visible }) => ({
  position: 'fixed',
  left: 0,
  top: 0,
  bottom: 0,
  width: visible ? 320 : 100,
  height: '100vh',
  backgroundColor: theme.white,
  color: theme.headlines,
  paddingTop: 96,
  borderRight: '1px solid #D6E0EA',
}));

const SidebarContent = styled('div')(() => ({
  padding: '48px 0 72px',
  borderBottom: '1px solid #D6E0EA',
}));

const SidebarItem = styled('div')(({ active }) => ({
  cursor: 'pointer',
  padding: '24px 40px',
  color: active ? theme.white : theme.primary,
  display: 'flex',
  alignItems: 'center',
  gap: 20,
  fontFamily: 'Lato',
  fontSize: '16px',
  lineHeight: 1.2,
  backgroundColor: active ? theme.brand : theme.white,
  transition: 'all .3s',

  '&:hover': {
    backgroundColor: theme.brand,
    color: theme.white,
  },
}));

export { SidebarContent, SidebarItem };
export default SidebarRoot;
