/* eslint-disable react-hooks/exhaustive-deps */
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import { PaginationItem, Table, TableBody, TableRow } from '@mui/material';
import { useGlobalFilter, usePagination, useTable } from 'react-table';
import ScrollBar from 'simplebar-react';
import React, { useMemo } from 'react';

import { BaseCard, H4 } from 'UI';
import {
  EmptyTableRoot,
  RootPagination,
  TableBodyCell,
  TableBodyRow,
  TableHeadCell,
  TableHeadRoot,
  TablePagination,
} from './BaseTable.styled';

import RightArrow from 'assets/images/RightArrow';
import LeftArrow from 'assets/images/LeftArrow';

const BaseTable = ({
  data,
  columnShape,
  onPaginate,
  totalPages,
  currentPageNo,
}) => {
  const columns = useMemo(() => columnShape, [data]);
  const tableData = useMemo(() => data, [data]);
  const isEmpty = data && data.length === 0;

  const { rows, prepareRow, headerGroups, getTableProps, getTableBodyProps } =
    useTable(
      {
        columns,
        data: tableData,
      },
      useGlobalFilter,
      usePagination,
    );

  const renderTable = (
    <Table {...getTableProps()}>
      <TableHeadRoot>
        {headerGroups.map((headerGroup) => (
          <TableRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <TableHeadCell
                {...column.getHeaderProps()}
                sx={{
                  minWidth: column.minWidth,
                }}
              >
                {column.render('Header')}
              </TableHeadCell>
            ))}
          </TableRow>
        ))}
      </TableHeadRoot>

      <TableBody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);

          return (
            <TableBodyRow {...row.getRowProps()}>
              {row.cells.map((cell) => (
                <TableBodyCell {...cell.getCellProps()}>
                  {cell.render('Cell')}
                </TableBodyCell>
              ))}
            </TableBodyRow>
          );
        })}
      </TableBody>
    </Table>
  );

  return (
    <BaseCard>
      <ScrollBar style={{ maxHeight: 600 }}>{renderTable}</ScrollBar>
      {isEmpty && (
        <EmptyTableRoot>
          <FolderOpenOutlinedIcon />
          <H4>No data</H4>
        </EmptyTableRoot>
      )}
      <RootPagination>
        <TablePagination
          shape="rounded"
          onChange={onPaginate}
          count={totalPages || 2}
          page={currentPageNo}
          renderItem={(item) => (
            <PaginationItem
              components={{ previous: LeftArrow, next: RightArrow }}
              {...item}
            />
          )}
        />
      </RootPagination>
    </BaseCard>
  );
};

export default BaseTable;
