import { useSelector, useDispatch } from 'react-redux';
import { useCallback } from 'react';

import {
  deleteNotificationAction,
  getBadgeMeAction,
  getNotifications,
  getStatisticMeAction,
  getSubscriptionMeAction,
  getUserMeAction,
  readNotificationAction,
} from './actions';

const useUser = () => {
  const dispatch = useDispatch();
  const {
    loading,
    userInfo,
    userSubscription,
    userStatistic,
    statisticLoader,
    badgeLoader,
    userBadge,
    notifications,
  } = useSelector((state) => state.user);
  const isUserLoading = loading === 'loading';
  const isUserStatisticLoading = statisticLoader === 'loading';
  const isUserBadgeLoading = badgeLoader === 'loading';

  const getUserMe = useCallback(async () => {
    const response = await dispatch(getUserMeAction());
    return response.payload;
  }, [dispatch]);

  const getUserNotifications = useCallback(async () => {
    const response = await dispatch(getNotifications());
    return response.payload;
  }, [dispatch]);

  const getSubscriptionMe = useCallback(async () => {
    const response = await dispatch(getSubscriptionMeAction());
    return response.payload;
  }, [dispatch]);

  const getStatisticMe = useCallback(async () => {
    const response = await dispatch(getStatisticMeAction());
    return response.payload;
  }, [dispatch]);

  const getBadgeMe = useCallback(async () => {
    const response = await dispatch(getBadgeMeAction());
    return response.payload;
  }, [dispatch]);

  const deleteNotification = useCallback(
    async (payload) => {
      const response = await dispatch(deleteNotificationAction(payload));
      return response.payload;
    },
    [dispatch],
  );

  const readNotification = useCallback(
    async (id) => {
      const response = await dispatch(readNotificationAction(id));
      return response.payload;
    },
    [dispatch],
  );

  return {
    isUserLoading,
    getUserMe,
    userBadge,
    getSubscriptionMe,
    getStatisticMe,
    userInfo,
    userSubscription,
    getUserNotifications,
    readNotification,
    userStatistic,
    isUserBadgeLoading,
    isUserStatisticLoading,
    deleteNotification,
    notifications,
    getBadgeMe,
  };
};

export default useUser;
