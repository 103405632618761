import { Box } from '@mui/material';
import React from 'react';

import ProgressRoot from './Progress.styled';
import { SmallText } from 'UI';

const Progress = ({
  label,
  value,
  progressWidth = '100%',
  textProgress,
  text,
}) => {
  return (
    <Box display="flex" alignItems="center" width="100%" gap="40px">
      <SmallText>{label}</SmallText>
      <Box width={progressWidth} marginLeft="auto">
        <ProgressRoot variant="determinate" value={value || 0} />
      </Box>
      {textProgress && (
        <div style={{ width: '90px', textAlign: 'right' }}>
          <SmallText>
            <div dangerouslySetInnerHTML={{ __html: text }} />
          </SmallText>
        </div>
      )}
    </Box>
  );
};

export default Progress;
